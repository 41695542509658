import PropTypes from 'prop-types';
import useSaveResponse from '@sportson/core-web/hooks/useSaveResponse';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import PostsContainer from '@sportson/core-web/containers/PostsContainer';
import { useState, useEffect } from 'react';

// TODO: NOTE: This is a temporary solution to get the products from Algolia until ProductsContainer is TypeScripted

export const PostsContainerToAlgoliaArticle = ({
    ids,
    children,
    currentPage = 0,
    pageSize = 9,
    filterCategory = null,
}) => {
    const [savedResponse, saveResponse] = useSaveResponse();
    const [savedCategories, saveCategories] = useSaveResponse();
    const [updatedPage, updatePage] = useState(1);
    const application = useAppSelector(({ application }) => application.site);
    const categoriesPageSize = 500;

    const getUniqueCategories = (articles) => {
        if (!articles) {
            return [];
        }
        const categoryArray = [];

        articles.forEach((article) => {
            if (Array.isArray(article.category)) {
                article.category.forEach((cat) => {
                    if (cat !== 'Uncategorized' && !categoryArray.includes(cat)) {
                        categoryArray.push(cat);
                    }
                });
            }
        });

        const sortedCategories = categoryArray.sort((a, b) => a.localeCompare(b));

        return Array.from(sortedCategories);
    };

    useEffect(() => {
        updatePage(currentPage + 1);
    }, [currentPage]);

    return (
        <>
            <PostsContainer
                ids={ids}
                application={application}
                postType="article"
                postTemplate="default"
                filterCategory={filterCategory}
                pageSize={categoriesPageSize}
                render={(props) => {
                    const { response } = props;
                    saveCategories(response);
                }}
            />
            <PostsContainer
                ids={ids}
                application={application}
                postType="article"
                postTemplate="default"
                page={updatedPage}
                filterCategory={filterCategory}
                pageSize={pageSize}
                render={(props) => {
                    const { response } = props;
                    saveResponse(response);
                    return (
                        savedResponse.current.posts &&
                        children({
                            response: savedResponse.current.posts,
                            data: {
                                data: savedResponse.current,
                                categories: getUniqueCategories(savedCategories.current.posts),
                            },
                        })
                    );
                }}
            />
        </>
    );
};

PostsContainerToAlgoliaArticle.propTypes = {
    ids: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    pageSize: PropTypes.number,
    filterCategory: PropTypes.string,
    currentPage: PropTypes.number,
};
