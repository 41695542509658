import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import getQueryParams from '@sportson/core-web/utils/getQueryParams';
import AlgoliaContainer from '@sportson/core-web/containers/AlgoliaContainer';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';

// toParamObject
const params = (ids, pagination, pageSize, filters = []) => ({
    facets: ['*'],
    page: pagination,
    hitsPerPage: pageSize,
    filters: [ids.map((id) => `external_id:"${id}"`), ...filters].filter((i) => i.length !== 0),
});

const paginationReset = (location, pagination, pageSize) => {
    const query = getQueryParams(location);
    const newPagination = query.postsPage ? query.postsPage : pagination;
    const newPageSize = pageSize;

    return [newPagination, newPageSize];
};

const PostsContainer = ({
    ids,
    index,
    location,
    page: pageFromProps,
    pageSize: pageSizeFromProps,
    postType,
    render,
    renderProps,
    responseCallback: responseCallbackFromProps,
    search,
    postTemplate,
    filterCategory,
}) => {
    const application = useAppSelector(({ application }) => application);
    const [initPage, initPageSize] = paginationReset(location, pageFromProps, pageSizeFromProps);
    const [pagination, setPagination] = useState({ page: initPage, pageSize: initPageSize });

    useEffect(() => {
        const [newPage, newPageSize] = paginationReset(location, pageFromProps, pageSizeFromProps);
        const newPagination = { page: newPage, pageSize: newPageSize };
        if (JSON.stringify(newPagination) !== JSON.stringify(pagination)) {
            setPagination(newPagination);
        }
    }, [location, pagination, pageFromProps, pageSizeFromProps]);

    const nextUrl = useCallback(() => {
        const query = getQueryParams(location);
        if (!query.postsPage) {
            query.postsPage = 1;
        }
        query.postsPage++;
        // todo: when nothing more to load return empty string
        return `${location.pathname}?${Object.entries(query)
            .map((q) => q.join('='))
            .join('&')}`;
    }, [location]);

    const responseCallback = useCallback(
        (response) => {
            if (!response) {
                return null;
            }
            const posts = response[0];
            const data = {
                filters: posts.facets,
                filterStats: posts.facets_stats,
                posts: posts.hits,
                postsCount: posts.hits.length,
                page: posts.page,
                pageSize: posts.pageSize,
                pageCount: posts.nbPages,
                hitsCount: posts.nbHits,
                hasMore: posts.hasMore,
                pagination: {
                    nextUrl,
                },
            };

            return responseCallbackFromProps ? responseCallbackFromProps(data) : data;
        },
        [responseCallbackFromProps, nextUrl],
    );

    const getParameters = () => {
        const parameters = [];
        const locale = application.site.locale.toLowerCase();
        const { page, pageSize } = pagination;

        const filters = [];
        if (postType) {
            filters.push([`post_type:${postType}`]);
        }

        if (postTemplate) {
            filters.push([`template:${postTemplate}`]);
        }

        if (filterCategory) {
            filters.push([`category:"${filterCategory}"`]);
        }

        parameters.push({
            indexName: `${index}_${locale}`,
            query: search,
            params: params(ids, page, pageSize, filters),
        });

        if (pageSize > 1000) {
            // How many queries should be made
            const pages = Math.ceil(pageSize / 1000);
            for (let i = 2; i < pages + 1; i++) {
                // One query gets 1000 hits total
                // If you want to get more you also have to change paginationLimitedTo in algolia index config
                parameters.push({
                    indexName: `${index}_${locale}`,
                    query: search,
                    params: params(ids, i, pageSize, filters),
                });
            }
        }

        return parameters;
    };

    return (
        <AlgoliaContainer
            parameters={getParameters()}
            responseCallback={responseCallback}
            render={(props) => render({ ...props, renderProps })}
        />
    );
};

PostsContainer.propTypes = {
    application: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    ids: PropTypes.array,
    index: PropTypes.string,
    location: PropTypes.object.isRequired,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    postType: PropTypes.string,
    render: PropTypes.func.isRequired,
    renderProps: PropTypes.object,
    responseCallback: PropTypes.func,
    search: PropTypes.string,
};

PostsContainer.defaultProps = {
    ids: [],
    index: 'posts',
    page: 1,
    pageSize: 36,
    postType: null,
    renderProps: {},
    responseCallback: null,
    search: '',
};

export default withRouter(PostsContainer);
